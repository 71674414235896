import * as React from "react";
import { ChangeEvent, KeyboardEvent, ReactNode } from "react";
import { Button, Checkbox, Dropdown, Icon, Input, Menu, Select } from "antd";
import { TableProps } from "antd/lib/table/interface";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { endpoints } from "@config/endpoints";
import { ajax } from "@lib/ajax";
import Spin from "antd/lib/spin";
import _ from "lodash";
import { PUBLIC_API_URL } from "@app/config/main";

type Endpoint = keyof typeof endpoints;
export type DataTableFilterType =
    | "dropdown"
    | "checkbox"
    | "input"
    | "version-dropdown"
    | "dropdown-search"
    | "dropdown-search-key-value"
    | "dropdown-multi";

export type IDataTableFilter = IDTFilter_Dropdown | IDTFilter_Dropdown2 | IDTFilter_Checkbox | IDTFilter_Input;

export interface IDataTableFilterBase {
    name: string;
    defaultValue?: string | boolean | number;
    isLocal?: boolean;
    label?: string;
    placeholder?: string;
    operand?: OP;
    getSRF?: (key: string | number | boolean) => SearchRequestFilter | SearchRequestFilter[];
}

interface IDTFilter_Dropdown extends IDataTableFilterBase {
    type: "dropdown" | "dropdown-search" | "dropdown-search-key-value" | "dropdown-multi";
    values: Record<string, string> | (() => Record<string, string>);
    searchable?: boolean;
}
interface IDTFilter_Dropdown2 extends IDataTableFilterBase {
    type: "dropdown" | "dropdown-search" | "dropdown-search-key-value" | "dropdown-multi";
    endpoint: Endpoint;
    searchable?: boolean;
}

interface IDTFilter_Checkbox extends IDataTableFilterBase {
    type: "checkbox";
    value: string | number | boolean;
}

interface IDTFilter_Input extends IDataTableFilterBase {
    type: "input";
}

interface IDataTableProps<T extends IData> extends TableProps<T> {
    store: DataTableStore<T>;
    filter: IDataTableFilter;
}

@observer
export class DataTableFilter<T extends IData> extends React.Component<IDataTableProps<T>> {
    @observable public selectedKey: string | boolean | number;
    @observable public fetching: boolean = false;
    @observable public values: any = {};

    constructor(props: IDataTableProps<T>) {
        super(props);
    }

    public async componentDidMount(): Promise<void> {
        const { store, filter } = this.props;
        if ("endpoint" in filter) {
            await this.loadFromApi(filter.endpoint);
        }

        if (filter.defaultValue) {
            if (filter.defaultValue !== "___") {
                store.dataProvider.addFilter(this.getRequestFilter(filter.defaultValue), filter.name);
            }
            this.selectedKey = filter.defaultValue;
        }
    }

    public async loadClients(): Promise<any> {
        const resp = await ajax.get({
            url: endpoints.client,
            params: {
                sort: ["id", "=", -1],
                limit: 50,
            },
        });
        this.values = resp.data.reduce((a: any, v: any) => ({ ...a, [v.email]: v.email }), {});
    }

    public async loadPartners(): Promise<any> {
        const resp = await ajax.get({
            url: endpoints.partners,
            params: {
                sort: ["id", "=", -1],
                limit: 50,
            },
        });
        this.values = resp.data.map((d: IPartner) => ({ name: d.name, value: d.id }));
    }

    public async loadPackages(): Promise<any> {
        const resp = await ajax.get({
            url: endpoints.package,
            params: {
                limit: 100,
                filters: [["type", "PACKAGE"]],
            },
        });
        this.values = resp.data.map((d: IPackage) => ({ name: d.title, value: d.id }));
    }

    public async loadModules(): Promise<any> {
        const resp = await ajax.get({
            url: endpoints.package,
            params: {
                limit: 100,
                filters: [["type", "MODULE"]],
            },
        });
        this.values = resp.data.map((d: IPackage) => ({ name: d.title, value: d.id }));
    }

    public async loadTags(): Promise<any> {
        const resp = await ajax.get({
            url: endpoints.tags,
            params: {
                limit: 100,
            },
        });
        this.values = resp.data.map((tag: ITag) => ({ ...tag, value: tag.id }));
    }

    public async loadDbConfigs(): Promise<any> {
        const resp = await ajax.get({
            url: endpoints.dbConfigs,
            params: {
                limit: 100,
            },
        });
        this.values = resp.data.map((config: IDbConfig) => ({ name: config.name, value: config.id }));
    }

    public loadVersions = async (): Promise<any> => {
        const resp = await ajax.get({
            url: PUBLIC_API_URL + "/admin/terminals/versions",
        });
        this.values = resp.data.reduce((acc: any, item: any) => {
            acc[item.v] = `${item.stage} ${item.v.split("-")[1]} (${item.count})`;
            return acc;
        }, {} as any);
    };

    public loadAppVersions = async (): Promise<any> => {
        const resp = await ajax.get({
            url: PUBLIC_API_URL + "/admin/terminals/app-versions",
        });
        this.values = resp.data.reduce((acc: any, item: any) => {
            acc[item.appV] = `(${item.os}) ${item.appV} (${item.count})`;
            return acc;
        }, {} as any);
    };

    public onFetchClient = _.debounce(async (value: string): Promise<void> => {
        this.fetching = true;
        let params: any = {
            sort: ["id", "=", -1],
        };
        if (value) {
            params = {
                filters: [["email", "=", value]],
            };
        }
        const resp = await ajax.get({
            url: endpoints.client,
            params,
        });
        this.fetching = false;
        this.values = resp.data.reduce((a: any, v: any) => ({ ...a, [v.email]: v.email }), {});
    }, 500);

    public loadFromApi = async (api: Endpoint | string): Promise<any[]> => {
        switch (api) {
            case "client":
                return await this.loadClients();
            case "partners":
                return await this.loadPartners();
            case "package":
                return await this.loadPackages();
            case "module":
                return await this.loadModules();
            case "tags":
                return await this.loadTags();
            case "dbConfigs":
                return await this.loadDbConfigs();
            case "versions":
                return await this.loadVersions();
            case "app-versions":
                return await this.loadAppVersions();
            default:
                return [];
        }
    };

    public onClick = (value: any) => {
        const key = _.isArray(value) ? value : _.isObject(value) ? value.key : value;
        const { store, filter } = this.props;
        this.selectedKey = key;
        if (filter.isLocal) {
            if (this.selectedKey === "___") {
                store.dataProvider.resetFilterByFn();
            } else {
                store.dataProvider.filterByFn(d => (d as any)[filter.name]! === this.selectedKey);
            }
        } else {
            if (key === "___" || _.isUndefined(value) || (_.isArray(key) && key.length === 0)) {
                store.dataProvider.removeFilter(this.props.filter.name);
            } else {
                const f = filter.operand ? [filter.name, filter.operand, key] : [filter.name, key];
                store.dataProvider.addFilter(this.getRequestFilter(key), this.props.filter.name);
            }
            store.dataProvider.loadData();
        }
    };

    private getRequestFilter(value: string | number | boolean): SearchRequestFilter {
        const filter = this.props.filter;
        if (typeof value === "boolean") {
            value = value ? "1" : "0";
        }
        let srf: SearchRequestFilter = filter.operand ? [filter.name, filter.operand, value] : [filter.name, value];
        if (filter.getSRF) {
            srf = filter.getSRF(value) as SearchRequestFilter;
        }
        return srf;
    }

    public loadData = _.debounce(
        () => {
            this.props.store.dataProvider.onPageChange(1, 100);
            this.props.store.dataProvider.loadData();
        },
        300,
        { trailing: true, leading: false },
    );

    public onSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const { store, filter } = this.props;
        const searchedValue = e.currentTarget.value;
        if (filter.isLocal) {
            if (searchedValue) {
                store.dataProvider.filterByFn(d => (d as any)[filter.name]!.indexOf(searchedValue) > -1);
            } else {
                store.dataProvider.resetFilterByFn();
            }
        } else {
            if (searchedValue) {
                store.dataProvider.addFilter(this.getRequestFilter(searchedValue), filter.name);
            } else {
                store.dataProvider.removeFilter(filter.name);
            }
            this.loadData();
        }
    };

    public onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            this.props.store.dataProvider.onPageChange(1, 100);
            this.props.store.dataProvider.loadData();
        }
    };

    private getValues(): Record<string, string> {
        if ("values" in this.props.filter) {
            return _.result(this.props.filter, "values", {});
        } else if (this.values) {
            return this.values;
        } else {
            return {};
        }
    }

    private onChecked = (e: any) => {
        const filter = this.props.filter as IDTFilter_Checkbox;
        // const isChecked = this.props.store.dataProvider.getFilter(filter.name);
        const isChecked = e.target.checked;
        console.log("checked", e.target.checked, e);
        if (isChecked) {
            this.props.store.dataProvider.addFilter(this.getRequestFilter(filter.value), filter.name);
        } else {
            this.props.store.dataProvider.removeFilter(filter.name);
        }
        this.props.store.dataProvider.loadData();
    };

    public render(): ReactNode {
        switch (this.props.filter.type) {
            case "dropdown-multi":
                return (
                    <Select
                        placeholder={this.props.filter?.placeholder}
                        showSearch={false}
                        allowClear
                        style={{ width: 200 }}
                        onChange={this.onClick}
                        mode="multiple"
                        optionFilterProp="children"
                        notFoundContent={this.fetching ? <Spin size="small" /> : null}
                        onSearch={this.props.filter?.searchable === false ? undefined : this.onFetchClient}
                        filterOption={false}
                    >
                        {_.map(this.values, data => (
                            <Select.Option key={data.name} value={data.value}>
                                {data.name}
                            </Select.Option>
                        ))}
                    </Select>
                );
            case "dropdown-search-key-value":
                return (
                    <Select
                        placeholder={this.props.filter?.placeholder}
                        showSearch
                        allowClear
                        style={{ width: 200 }}
                        onChange={this.onClick}
                        optionFilterProp="children"
                        notFoundContent={this.fetching ? <Spin size="small" /> : null}
                        onSearch={this.onFetchClient}
                        filterOption={false}
                    >
                        {_.map(this.values, data => (
                            <Select.Option key={data.name} value={data.value}>
                                {data.name}
                            </Select.Option>
                        ))}
                    </Select>
                );
            case "dropdown-search":
                return (
                    <Select
                        placeholder={this.props.filter?.placeholder || "Select email"}
                        showSearch
                        allowClear
                        style={{ width: 200 }}
                        onChange={this.onClick}
                        optionFilterProp="children"
                        notFoundContent={this.fetching ? <Spin size="small" /> : null}
                        onSearch={this.onFetchClient}
                        filterOption={false}
                    >
                        {_.map(this.values, (val, key) => (
                            <Select.Option key={val} value={val}>
                                {val}
                            </Select.Option>
                        ))}
                    </Select>
                );
            case "dropdown":
                const placeholder = _.capitalize(
                    this.props.filter.placeholder || this.props.filter.label || this.props.filter.name,
                );
                const minWidth = Math.max(placeholder.length * 13 + 20, 100);
                return (
                    <div className="filter-wrapper">
                        {!this.selectedKey ||
                            (this.selectedKey !== "___" && <div className="filter-label">{placeholder}</div>)}
                        <Select
                            key={this.props.filter.name}
                            showSearch
                            allowClear
                            onChange={this.onClick}
                            value={this.selectedKey !== "___" ? this.selectedKey?.toString() : undefined}
                            style={{ minWidth }}
                            placeholder={placeholder}

                            // trigger={["click"]}
                            // overlay={
                            //     <Menu onClick={this.onClick} selectedKeys={[`${this.selectedKey}`]}>
                            //         <Menu.Item key="___">No Filter</Menu.Item>
                            //         {_.map(this.getValues(), (val, key) => (
                            //             <Menu.Item key={key}>{val}</Menu.Item>
                            //         ))}
                            //     </Menu>
                            // }
                        >
                            {_.map(this.getValues(), (val, key) => (
                                <Select.Option key={key} value={key}>
                                    {val}
                                </Select.Option>
                            ))}
                            {/* <Button>
                            {this.props.filter.label || this.props.filter.name}
                            {this.selectedKey !== "___"
                                ? ": " + ((this.props.filter.values ?? this.values) as any)[this.selectedKey as any]
                                : ""}
                            <Icon type="down" />
                        </Button> */}
                        </Select>
                    </div>
                );
            case "input":
                return (
                    <div style={{ display: "inline-block" }}>
                        <Input
                            placeholder={
                                this.props.filter.placeholder || this.props.filter.label || this.props.filter.name
                            }
                            onChange={this.onSearch}
                            defaultValue={_.last(
                                this.props.store.dataProvider.getFilter(this.props.filter.name) as any,
                            )}
                        />
                    </div>
                );
            case "checkbox":
                return (
                    <div style={{ display: "inline-flex", alignItems: "center" }}>
                        <Checkbox
                            onChange={this.onChecked}
                            // onClick={this.onChecked}
                            checked={!!this.props.store.dataProvider.getFilter(this.props.filter.name)}
                        />
                        <span style={{ padding: "0 8px" }}>{this.props.filter.label || this.props.filter.name}</span>
                    </div>
                );
            default:
                return null;
        }
    }
}
