import {DataTableStore} from "@app/components/DataTable/DataTableStore";
import {FormDrawerStore} from "@app/components/FormDrawer/FormDrawerStore";
import {endpoints} from "@app/config/endpoints";
import {action, observable} from "mobx";
import {transactionTableStore} from "@pages/transactions/TransactionsPage/transactionTableStore";
import {dummyTransaction, refineTransactionFromApi} from "@app/actions/transaction-actions";
import _ from "lodash";
import {ajax} from "@lib/ajax";

export class TransactionFormStore extends FormDrawerStore<ITransaction> {
    public autofocus = "type";
    public dataName = "transaction";
    public dummyDataFn = dummyTransaction;
    public apiUrl = endpoints.transaction;
    @observable public _data = dummyTransaction();
    public afterSaveCallback?: (d: ITransaction) => void;
    @observable public brands: IBrand[] = [];
    @observable public prices: any[] = [];
    @observable public fetching: boolean = false;

    public beforeShow = async (): Promise<void> => {
        await this.loadBrands();
    }

    get tableStore(): DataTableStore {
        return transactionTableStore;
    }
    set tableStore(s: DataTableStore) {
        
    }

    public async loadBrands(): Promise<void> {
        const resp = await ajax.get({
            url: endpoints.brand,
            params: {
                sort: ["id", "=", -1]
            }
        });
        this.brands = resp.data;
    }

    public async loadPrices(brandId:number): Promise<void> {
        const resp = await ajax.get({
            url: `/transaction/${brandId}/prices`
        });
        this.prices = resp.data;
    }

    @action
    public setBrandId = (id: number) => {
        this.data.brand_id = id;
        this.data.brand = _.find(this.brands, {id});
        //this.loadPrices(id);
    }

    public onFetchClient = _.debounce(async (value: string): Promise<void> => {
        this.fetching = true;
        let params: any = {
            sort: ["id", "=", -1]
        };
        if (value) {
            params = {
                filters: [["name", "=", value]]
            };
        }
        const resp = await ajax.get({
            url: endpoints.brand,
            params
        });
        this.fetching = false;
        this.brands = resp.data;
    }, 500);

    public afterSave = (d: ITransaction) => {
        d = refineTransactionFromApi(d);
        transactionTableStore.dataProvider.updateLocalCopyOfData(d);
        if (this.afterSaveCallback) {
            this.afterSaveCallback(d);
            this.afterSaveCallback = undefined;
        }
    }
}

export const transactionFormStore = new TransactionFormStore;
