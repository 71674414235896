import * as React from "react";
import { editButton, deleteButton } from "@app/components/DataTable/actions";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { endpoints } from "@app/config/endpoints";
import { partnersFormStore } from "../PartnerForm/PartnerFormStore";
import { PartnersForm } from "../PartnerForm/PartnerForm";
import { DataProvider } from "@app/components/DataTable/DataProvider";
import { CountriesAll } from "@app/lib/utils";

export const partnerTableStore = new DataTableStore<IPartner>({
    dataProvider: new DataProvider({
        url: endpoints.partners,
        with: ['parent'],
        filters: [

        ]
    }),
    formStore: () => partnersFormStore,
    form: PartnersForm,
    columnsManager: {
        hiddenColumns: ["created_at"]
    },
    filters: [
        {
            name: "type",
            type: "dropdown",
            values: {
                "PARTNER": "Partner",
                "DEALER": "Dealer"
            }
        },
    ],
    columns: [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            align: "center",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "center",
        },
        {
            title: "Parent",
            dataIndex: "parent",
            key: "parent",
            align: "center",
            render: p => p?.name ?? '-'
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            align: "center",
        },
        // {
        //     title: "Commission",
        //     dataIndex: "commission",
        //     key: "commission",
        //     align: "center",
        //     render: c => c ?? 0
        // },
        {
            title: "Countries",
            dataIndex: "countries",
            key: "id",
            align: "center",
            render: c => c.map((cM: string) => CountriesAll.find(cA => cA.value === cM)?.name).join(", ")
        },
        {
            title: "Created at",
            dataIndex: "created_at",
            key: "created_at",
            align: "center",
        },
        {
            title: "Actions",
            key: "actions",
            width: 200,
            render: actions
        },
    ]
});

const editBtn = editButton(partnerTableStore);
const deleteBtn = deleteButton(partnerTableStore);

function actions(data: any): JSX.Element {
    return <>
        {editBtn(data)}
        {deleteBtn(data)}
    </>;
}