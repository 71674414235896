import { FormDrawer } from "@app/components/FormDrawer/FormDrawer";
import { CountriesAll, eSet, set } from "@app/lib/utils";
import { partnersFormStore } from "@app/pages/partners/PartnerForm/PartnerFormStore";
import { Button, Form, Input, Select } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import ReactJson from "react-json-view";
import _ from "lodash";
import { partnerTableStore } from "@pages/partners/PartnerPage/partnerTableStore";
import { sessionStore } from "@app/stores/SessionStore";

@observer
class PartnersFormBare extends React.Component<FormComponentProps> {

    public componentDidMount(): void {
        partnersFormStore.formUtils = this.props.form;
    }

    public handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        this.props.form.validateFields((err: Error | undefined, values: any) => {
            if (!err) {
                partnersFormStore.save();
            }
        });
    };

    public render(): JSX.Element {
        const partner = partnersFormStore.data;
        const { getFieldDecorator } = this.props.form;

        const setInformation = (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
            partnersFormStore.setData({
                ...partner,
                information: {
                    ...partner.information,
                    [key]: e.target.value,
                },
            });
        };

        const selectType = (value: string) => {

            set(partner, "type")(value);

            if (value === "PARTNER") {
                set(partner, "parent_id")(null);
            }
        };

        const setCommissionConfig = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            let value = partner.commission_config ?? {};

            try {
                value = JSON.parse(e.target.value);
            } catch (e) {

            }

            set(partner, "commission_config")(value);
        }

        const types = [{ name: "Partner", value: "PARTNER" }, { name: "Dealer", value: "DEALER" }];

        return <>
            <FormDrawer store={partnersFormStore}>
                <Form layout="vertical" onSubmit={this.handleSubmit}>

                    <Form.Item label="Name">
                        {getFieldDecorator("name", {
                            initialValue: partner.name,
                            rules: [{ required: true }],
                        })(
                            <Input placeholder="Name" onChange={eSet(partner, "name")} />,
                        )}
                    </Form.Item>

                    {partner.type === "DEALER" ? <Form.Item label="Partner">
                        {/* tslint:disable-next-line:max-line-length */}
                        <Select defaultValue={_.get(partner, "parent_id")} onChange={set(partner, "parent_id")}>
                            {partnerTableStore.dataProvider.list.filter(p => p.type === "PARTNER").map(p =>
                                <Select.Option key={p.id} value={p.id}>
                                    {p.name}
                                </Select.Option>)}
                        </Select>
                    </Form.Item> : null}

                    <Form.Item label="Type">
                        {/* tslint:disable-next-line:max-line-length */}
                        <Select defaultValue={_.get(partner, "type")}
                                onChange={selectType}>
                            {
                                types.map(type =>
                                    <Select.Option key={type.value} value={type.value}>
                                        {type.name}
                                    </Select.Option>)
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item label="Countries">
                        {/* tslint:disable-next-line:max-line-length */}
                        <Select mode="multiple" defaultValue={_.get(partner, "countries")} onChange={set(partner, "countries")}>
                            {CountriesAll.map(country => <Select.Option key={country.name} value={country.value}>
                                {country.name}
                            </Select.Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Number">
                        {getFieldDecorator("information.number", {
                            initialValue: partner.information?.number,
                            rules: [{ required: false }],
                        })(
                            <Input placeholder="+9940000000000" onChange={setInformation("number")} />,
                        )}
                    </Form.Item>

                    <Form.Item label="Whatsapp">
                        {getFieldDecorator("information.whatsapp", {
                            initialValue: partner.information?.whatsapp,
                            rules: [{ required: false }],
                        })(
                            <Input placeholder="+9940000000000" onChange={setInformation("whatsapp")} />,
                        )}
                    </Form.Item>

                    <Form.Item label="Telegarm">
                        {getFieldDecorator("information.telegram", {
                            initialValue: partner.information?.telegram,
                            rules: [{ required: false }],
                        })(
                            <Input placeholder="https://t.me/@clopos" onChange={setInformation("telegram")} />,
                        )}
                    </Form.Item>

                    <Form.Item label="Web site">
                        {getFieldDecorator("information.website", {
                            initialValue: partner.information?.website,
                            rules: [{ required: false }],
                        })(
                            <Input placeholder="https://clopos.com" onChange={setInformation("website")} />,
                        )}
                    </Form.Item>

                    <Form.Item label="Email">
                        {getFieldDecorator("information.email", {
                            initialValue: partner.information?.email,
                            rules: [{ required: false }],
                        })(
                            <Input placeholder="partner@clopos.com" onChange={setInformation("email")} />,
                        )}
                    </Form.Item>

                    <Form.Item label="Address">
                        {getFieldDecorator("information.address", {
                            initialValue: partner.information?.address,
                            rules: [{ required: false }],
                        })(
                            // tslint:disable-next-line:max-line-length
                            <Input placeholder="1. 28 May street, Baku, Azerbaijan"
                                   onChange={setInformation("address")} />,
                        )}
                    </Form.Item>

                    {sessionStore?.user?.role?.name === 'owner' ? <Form.Item label="Commission Config">
                        {getFieldDecorator("commission_config", {
                            initialValue: JSON.stringify(partner.commission_config ?? {}, null, 2),
                            rules: [{ required: false }],
                        })(
                            // tslint:disable-next-line:max-line-length
                            <Input.TextArea autoSize={{ minRows:4 }} rows={4} placeholder="json config" onChange={setCommissionConfig}/>
                        )}
                    </Form.Item> : null}

                    <ReactJson src={toJS(partner)} collapsed={1} name={partnersFormStore.dataName} />

                    <Button type="primary" loading={partnersFormStore.loading}
                            htmlType="submit" style={{ marginRight: 5 }}>
                        Save
                    </Button>
                    <Button type="default" onClick={partnersFormStore.hide} htmlType="button">Cancel</Button>

                </Form>
            </FormDrawer>
        </>;
    }
}

export const PartnersForm = Form.create()(PartnersFormBare);
