import { DataTable } from "@app/components/DataTable/DataTable";
import { observer } from "mobx-react";
import * as React from "react";
import { transactionTableStore } from "@pages/transactions/TransactionsPage/transactionTableStore";
import moment from "moment";
import { partnerTableStore } from "@app/pages/partners/PartnerPage/partnerTableStore";
import { MonthlyTransactionsChart } from "./MonthlyTransactionsChart";
import { computed, toJS } from "mobx";
import { Table } from "antd";

@observer
export class TransactionsPage extends React.Component {
    public componentDidMount(): void {
        // noinspection JSIgnoredPromiseFromCall
        transactionTableStore.dataProvider.loadData();
        partnerTableStore.dataProvider.loadData();
    }

    @computed
    public get calculateLast30DaysTotalRevenue(): { amount: number, commission: number } {

        return transactionTableStore.dataProvider.list
            .filter(t => moment().diff(moment(t.created_at), "days") <= 30)
            .reduce((acc:any, trans) => {

                acc.amount += parseFloat(trans.amount?.toString());
                acc.commission += parseFloat(trans.commission?.toString() ?? 0);

                return acc;
            },{
                amount: 0,
                commission: 0,
            });
    }

    public render(): JSX.Element {
        return (
            <div>
                <MonthlyTransactionsChart />
                <br />
                <h2>Transactions</h2>
                <table className={"table ant-table-bordered"}>
                    <thead className={"ant-table-thead"}>
                        <tr>
                            <th>Amount</th>
                            <th>Commission</th>
                        </tr>
                    </thead>
                    <tbody className={"ant-table-tbody"}>
                        <tr>
                            <td>{this.calculateLast30DaysTotalRevenue.amount}</td>
                            <td>{this.calculateLast30DaysTotalRevenue.commission}</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <div>
                    <DataTable store={transactionTableStore} bordered />
                </div>
            </div>
        );
    }
}
