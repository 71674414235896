import * as React from "react";
import { observer } from "mobx-react";
import { Button, Drawer, Input, message } from "antd";
import { action, observable } from "mobx";
import {Ajax} from "@lib/ajax";
import { sessionStore } from "@stores/SessionStore";

const state = observable({
    visible: false,
    user: {} as IUser,
    loading: false,
    visibleQr: false,
    qr_image: null,
    secret_key: null,
    otp: null as (number | string | null),
});

export const showUserDrawer = action((user: IUser): void => {
    state.user = user;
    state.visible = true;
});

const QrModal = observer(() => {

    const enableTwoFactor = async () => {
        state.loading = true;
        const ajax = new Ajax();
        ajax.setToken(sessionStore.token);
        try {
            const { success } = await ajax.load({
                url: "/auth/enable-2fa",
                method: "put",
                data:{
                    secret_key: state.secret_key,
                    otp: state.otp
                }
            });
            if (success) {
                message.success("Two factor authentication enabled");
                state.user.two_factor = "true";
                closeQr();
            }
        } catch (e) {
            message.error(e.message);
        } finally {
            state.loading = false
        }
    };

    const closeQr = () => {
        state.qr_image = null;
        state.secret_key = null;
        state.otp = null;
        state.visibleQr = false;
    }

    return <Drawer
        title="Two factor authentication QR code"
        width={500}
        placement="right"
        closable={false}
        onClose={() => {
            if (!state.loading) {
                closeQr()
            }
        }}
        visible={state.visibleQr}>

        <p>Scan this QR code with your authenticator app</p>
        {state.qr_image ? <img src={state.qr_image ?? ""} alt="QR code" /> : null}
        <p>Secret key: {state.secret_key}</p>
        <Input className={"mb-2"} placeholder="Enter OTP" onChange={(e) => {
            state.otp = e.currentTarget.value
        }} />
        <br/>
        <hr/>
        <div className={"ant-row-flex ant-row-flex-end"}>
            <Button disabled={!state.otp} loading={state.loading} color="default" type={'primary'} onClick={enableTwoFactor}>
                Enable
            </Button>
        </div>
    </Drawer>;
});

export const UserDrawer = observer(() => {

    const getQrCodeForTwoFactor = async () => {
        state.loading = true;
        const ajax = new Ajax();
        ajax.setToken(sessionStore.token);
        try {
            const { success,data : {qr_image,secret_key} } = await ajax.load({
                url: "/auth/generate-2fa-qr",
                method: "get",
            });

            if (success) {
                state.qr_image = qr_image;
                state.secret_key = secret_key;
                state.visibleQr = true; // open qr modal
            } else {
                message.error("Failed to generate QR code");
            }

        } catch (e) {
            message.error(e.message);
        } finally {
            state.loading = false
        }
    };



    return <Drawer
        title="User info"
        width={500}
        placement="right"
        closable={false}
        onClose={() => state.visible = false}
        visible={state.visible}>

        <QrModal />

        {state.user && <>
            <h2>{state.user.username}</h2>

            {/* <p>{state.user.token}</p> */}
            {/* <ReactJson src={sessionStore.user} /> */}
            <p>
                <strong>ID: </strong>
                {state.user.id}
            </p>
            <p>
                <strong>Username: </strong>
                {state.user.username}
            </p>
            <p>
                <strong>Creted at: </strong>
                {state.user.created_at}
            </p>
            <p>
                <strong>Updated at: </strong>
                {state.user.updated_at}
            </p>
            {state.user.role && <>
                <p>
                    <strong>Role: </strong>
                    {state.user.role!.title} : {state.user.role!.level}
                </p>
            </>}

            <p>
                <strong>Two factor: </strong>
                {state.user.two_factor
                    ? "Enabled" :
                    <Button loading={state.loading}  color="default" type={'primary'} onClick={getQrCodeForTwoFactor}>
                        Enable
                    </Button>
                }
            </p>

        </>}

    </Drawer>;
});

UserDrawer.displayName = "UserDrawer";
