import { observable, action, toJS } from "mobx";
import { depot } from "@cloposcom/libs";
import { ajax } from "@app/lib/ajax";
import { message } from "antd";
import { attempt } from "@app/lib/utils";
import { refineUserFromApi, getUserById } from "@app/actions/user-actions";
import { loadAppData } from "./AppStore";
import _ from "lodash";

class SessionStore {
    @observable public payload = {
        username: "",
        password: "",
        two_factor_code: "",
        two_factor: false,
    };

    @observable public user: IUser;

    @observable public loading = false;

    @observable public isLoggedIn = false;
    @observable public token: string = "";
    @observable public username: string = "";
    @observable public userId: number;
    @observable public role: IRoleResponse | null;
}

export const sessionStore = new SessionStore();

export const initSessionStore = action(async () => {
    if (depot.getItem("isLoggedIn")) {
        await loadSessionData();
    }
});

export const attemptLogin = action(async () => {
    await attempt(
        async () => {
            sessionStore.loading = true;
            const resp = await ajax.post({
                url: "/auth/login",
                data: _.omitBy(sessionStore.payload, _.isEmpty)
            });
            if (resp.success) {
                if (resp.data?.partner) {
                    const msg = "Partners cannot login to Admin";
                    message.error(msg);
                    throw new Error(msg);
                }

                if (resp.data?.two_factor === true) {
                    sessionStore.payload.two_factor = true;
                    message.success("Please enter the 2FA code");
                    sessionStore.loading = true;
                    return;
                }


                ajax.setToken(resp.data.api_token);
                sessionStore.token = resp.data.api_token;
                sessionStore.username = resp.data.username;
                sessionStore.userId = resp.data.id;
                sessionStore.role = resp.data.roles?.[0] || null;
                sessionStore.user = refineUserFromApi(resp.data);
                await loadAppData();
                sessionStore.isLoggedIn = true;
                persistSessionData();
                // window.location.href = "/";
            }
        },
        undefined,
        () => {
            sessionStore.loading = false;
        },
    );
});

const persistSessionData = action(() => {
    const fields = ["isLoggedIn", "token", "username", "userId", "role"];
    fields.forEach(field => depot.setItem(field, (sessionStore as any)[field]));
    // depot.setItem("userId", sessionStore.user.id);
});

const loadSessionData = action(async () => {
    const fields = ["isLoggedIn", "token", "username", "role"];
    fields.forEach(field => ((sessionStore as any)[field] = depot.getItem(field)));
    ajax.setToken(sessionStore.token);
    await loadUser();
    if (sessionStore.user.partner_id) {
        endSession();
        throw new Error("Partners cant login to Admin");
    }
});

export const endSession = action(() => {
    sessionStore.isLoggedIn = false;
    sessionStore.token = "";
    sessionStore.username = "";
    sessionStore.userId = 0;
    persistSessionData();
});

export const loadUser = action(async () => {
    sessionStore.user = await getUserById();
});
