import { ajax } from "@app/lib/ajax";
import React, { useEffect, useState, FC } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { endpoints } from "@app/config/endpoints";
import moment, { Moment } from "moment";
import _ from "lodash";
import { addDays, subMonths } from "date-fns";
import { observer } from "mobx-react";
import { transactionTableStore } from "./transactionTableStore";
import { toJS } from "mobx";

const chartTitle = "Monthly transactions";

const fetchReportData = async (date: Moment[], filters?: SearchRequestFilter[]) => {
    // const filters = _.toPairs(conf);

    return ajax
        .get<IBrand[]>({
            url: endpoints.transactionDashboard,
            params: {
                date: date.map(d => d.format("YYYY-MM-DD")),
                filters,
            },
        })
        .then(res => res.data);
};

interface IProps {
    filters?: SearchRequestFilter[];
}

export const MonthlyTransactionsChart: FC<IProps> = observer(props => {
    const dp = transactionTableStore.dataProvider;

    const filters = [dp.getFilter("method"), dp.getFilter("country"), dp.getFilter("partnerId")].filter(
        f => f,
    ) as SearchRequestFilter[];

    const filtersStr = filters.toString();
    const [m1Data, setM1Data] = useState<any[]>([]);
    const [m2Data, setM2Data] = useState<any[]>([]);

    const [cumuM1Data, setCumuM1Data] = useState<any[]>([0]);
    const [cumuM2Data, setCumuM2Data] = useState<any[]>([0]);
    useEffect(() => {
        (async () => {
            const lastMonth = subMonths(new Date(), 1);
            const m1: any = await fetchReportData(
                [moment(lastMonth).startOf("month"), moment(lastMonth).endOf("month")],
                filters,
            );
            const m2: any = await fetchReportData([moment().startOf("month"), moment().endOf("month")], filters);

            const m1Cumu: any[] = [];
            const m2Cumu: any[] = [];

            setM1Data(
                m1.map((r: any, i: number) => {
                    m1Cumu.push({
                        y: (m1Cumu[i - 1]?.y || 0) + r.total_amount,
                        x: new Date(r.date),
                        count: (m1Cumu[i - 1]?.count || 0) + r.count,
                    });
                    return {
                        y: r.total_amount,
                        x: new Date(r.date),
                        count: r.count,
                    };
                }),
            );
            setCumuM1Data(m1Cumu);

            try {
                const lastDayOfThisMonth = Number(moment(m2[m2.length - 1].date).format("DD"));
                const lastRemainingDayOfThisMonth = Number(moment().endOf("month").format("DD"));
                const diffDays = lastRemainingDayOfThisMonth - lastDayOfThisMonth;

                let arrDays = m2.map((r: any, i: number) => {
                    m2Cumu.push({
                        y: (m2Cumu[i - 1]?.y || 0) + r.total_amount,
                        x: new Date(r.date),
                        count: (m2Cumu[i - 1]?.count || 0) + r.count,
                    });
                    return {
                        y: r.total_amount,
                        x: new Date(r.date),
                        count: r.count,
                    };
                });

                if (diffDays > 0) {
                    arrDays = [
                        ...arrDays,
                        ...[...Array(diffDays)].map((_item, i) => ({
                            y: 0,
                            x: addDays(new Date(), i + 1),
                            count: 0,
                        })),
                    ];
                }

                setM2Data(arrDays);
                setCumuM2Data(m2Cumu);
            } catch (ex) {
                setM2Data(
                    m1.map((r: any, i: number) => {
                        m2Cumu.push({
                            y: (m2Cumu[i - 1]?.y || 0) + r.total_amount,
                            x: new Date(r.date),
                            count: (m2Cumu[i - 1]?.count || 0) + r.count,
                        });
                        return {
                            y: r.total_amount,
                            x: new Date(r.date),
                            count: r.count,
                        };
                    }),
                );
                setCumuM2Data(m2Cumu);
            }
        })();
    }, [filtersStr]);

    return (
        <div style={{ flexBasis: "30%" }} className="widget">
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    credits: { enabled: false },
                    title: {
                        text: chartTitle,
                    },
                    yAxis: [
                        {
                            title: {
                                text: "",
                            },
                        },
                        {
                            title: {
                                text: "",
                            },
                            opposite: true,
                        },
                    ],
                    colors: ["#f4bd8e", "#7cb5ec", "#8ef4e2", "#d88ef4"],
                    xAxis: [
                        {
                            type: "datetime",
                            visible: false,
                        },
                        {
                            type: "datetime",
                            visible: false,
                        },
                        {
                            type: "datetime",
                            visible: false,
                        },
                        {
                            type: "datetime",
                            visible: false,
                        },
                    ],
                    series: [
                        {
                            type: "column",
                            name: "Last month",
                            xAxis: 0,
                            data: m1Data,
                        },
                        {
                            type: "column",
                            name: "This month",
                            xAxis: 1,
                            data: m2Data,
                        },
                        {
                            name: "Last month (cumulative)",
                            yAxis: 1,
                            xAxis: 0,
                            data: cumuM1Data,
                        },
                        {
                            name: "This month (cumulative)",
                            yAxis: 1,
                            xAxis: 1,
                            data: cumuM2Data,
                        },
                    ],
                    tooltip: {
                        formatter(): string {
                            return `<div>
                            Amount: <strong>${this.y}₼</strong><br/>
                            ${
                                this.point.options.count
                                    ? `Count: <strong>${this.point.options.count}</strong><br/>`
                                    : ""
                            }
                            Date: <strong>${moment(this.x).format("DD MMMM, dd")}</strong>
                        </div>`;
                        },
                    },
                }}
            />
        </div>
    );
});
