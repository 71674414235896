import { DataTable } from "@app/components/DataTable/DataTable";

import { observer } from "mobx-react";
import * as React from "react";
import { tableStore } from "./tableStore";
import { sessionStore } from "@stores/SessionStore";

@observer
export class DbConfigPage extends React.Component {

    public componentDidMount(): void {
        tableStore.dataProvider.loadData();
    }

    public render(): JSX.Element {
        return ([1, 3, 35, 76].includes(sessionStore.user.id!)) ? <div>
            <h2>Databases</h2>
            <div>
                <DataTable store={tableStore} bordered pagination={false}/>
            </div>
        </div> : <div>
            <h2>Access denied</h2>
        </div>;
    }
}
