import _ from "lodash";

export function refineSubscription(resp: IApiResponse): ITotalData[] {
    const data: ITotalData[] = [];
    resp.data.map((d: any) => {
        const t = _.find(data, ["date", d.month]);
        if (t) {
            t.count += d.total_amount;
            if (d?.method !== "REMAIN") {
                t.unique_brand_count += d.unique_brand_count;
            }
            t.list.push(`${d.method}: ${d.total_amount}`);
        } else {
            data.push({
                count: d.total_amount,
                date: d.month,
                unique_brand_count: d.unique_brand_count,
                list: [`${d.method}: ${d.total_amount}`],
            });
        }
    });

    return data;
}
