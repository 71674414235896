import {endpoints} from "@app/config/endpoints";
import {saveData} from "@app/lib/api-utils";
import moment from "moment-timezone";
import { sessionStore } from "@stores/SessionStore";

const url = endpoints.transaction;
export const saveTransaction = (transaction: ITransaction) => saveData({ url }, transaction);

export const refineTransactionFromApi = (d: ITransaction) => {
    d.created_at = moment(d.created_at).tz('UTC').format("LLL");
    return d;
}

export const refineCloposPayTransactionFromApi = (d: ITransaction) => {
    d.created_at = moment(d.created_at).format("LLL");
    d.updated_at = moment(d.updated_at).format("LLL");
    d.sent_to_waiter_at = d.sent_to_waiter_at ? moment(d.sent_to_waiter_at).format("LLL") : d.sent_to_waiter_at;
    d.sent_to_brand_at = d.sent_to_brand_at ? moment(d.sent_to_brand_at).format("LLL") : d.sent_to_brand_at;
    return d;
};

export const dummyTransaction = (transaction?: Partial<ITransaction>): ITransaction => {
    return {
        method: "CASH",
        amount: 0,
        currency: "AZN",
        note: "",
        status: "PENDING",
        created_at: Date.now().toString(),
        updated_at: Date.now().toString(),
        brand: {},
        user_id: sessionStore.user.id,
        month: 1,
        ...transaction
    } as ITransaction ;
};
